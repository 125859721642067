import axios from 'axios';
import { UserLoginType } from '../enum';
import { Environment, getCurrentApiEnv } from './Environment';
import { getToken } from './GetToken';

export default class HttpPostalCodeService {
  service: any;

  constructor() {
    this.service = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'X-User-Login-Type': UserLoginType.UserId,
      },
    });

    this.service.defaults.headers.common.Authorization = getToken();
  }

  getFunction = (path: string) => {
    this.service.defaults.headers = {
      ...this.service.defaults.headers,
      'x-functions-key':
        Environment.baseUrl[getCurrentApiEnv()].funcPostalCode.code,
    };

    const url =
      Environment.baseUrl[getCurrentApiEnv()].funcPostalCode.url + path;
    return this.service.get(url);
  };

  get(path: string) {
    const url =
      Environment.baseUrl[getCurrentApiEnv()].funcPostalCode.url + path;
    return this.service.get(url);
  }
}
