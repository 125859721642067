export const GET_CART = 'GET_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const CART_ORDER_MESSAGE_DISABLE = 'CART_ORDER_MESSAGE_DISABLE';
import { CONST_LOCALSTORAGE } from '../../constants/localStorage';

const initialState = {
  quantity: 0,
  amount: 0,
  cartOrderMessageAdd: false,
};

function getCart() {
  const cart = localStorage.getItem(CONST_LOCALSTORAGE.CART_INFORMATION);
  return JSON.parse(cart);
}

function updateCart(state, action) {
  localStorage.setItem(
    CONST_LOCALSTORAGE.CART_INFORMATION,
    JSON.stringify(action.payload)
  );
  return {
    ...state,
    ...action.payload,
  };
}

function cartOrderMessageDisable(state) {
  localStorage.setItem(
    CONST_LOCALSTORAGE.CART_INFORMATION,
    JSON.stringify({ ...state, cartOrderMessageAdd: false })
  );
  return {
    ...state,
    cartOrderMessageAdd: false,
  };
}

export default function Cart(state = initialState, action) {
  switch (action.type) {
    case GET_CART:
      return {
        ...state,
        ...getCart(),
      };
    case UPDATE_CART:
      return updateCart((state = initialState), action);
    case CART_ORDER_MESSAGE_DISABLE:
      return cartOrderMessageDisable({ ...getCart() });
    default:
      return state;
  }
}
