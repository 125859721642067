import Spinner from '../Spinner';

const SpinnerPage = () => {
  return (
    <div className="container-spinner">
      <Spinner
        className="spinner-border-sm me-1 spinner-center"
        tag="span"
        color="white"
        size="lg"
      />
    </div>
  );
};

export default SpinnerPage;
