import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { HelmetProvider } from 'react-helmet-async';

import { Provider } from 'react-redux';
import App from './App';

import { configureStore } from './redux/store';
import { Environment, getCurrentApiEnv } from './config/Environment';

import './i18n';

// Ajuste para os métodos do protótipo Node
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child, ...args): any {
    if (child.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot remove a child from a different parent',
          child,
          this
        );
      }
      return child;
    }
    return originalRemoveChild.apply(this, [child, ...args]);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode, ...args): any  {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this
        );
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, [newNode, referenceNode, ...args]);
  };
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={Environment.baseUrl[getCurrentApiEnv()].auth0Domain}
      clientId={Environment.baseUrl[getCurrentApiEnv()].auth0ClientId}
      audience={Environment.baseUrl[getCurrentApiEnv()].auth0Audience}
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
    >
      <Provider store={configureStore({})}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
