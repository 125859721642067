import HttpOrdersService from '../../config/HttpOrders';
import { EnumScenario, EnumStateCode } from '../../enum';
import { MessageSignalRReportOrders } from '../../pages/panelOperation';
import {
  GetNextStateResponse,
  TCancelStatusPayload,
  TDropByBackoffice,
} from './orders.type';

export class OrderService {
  api: HttpOrdersService;

  constructor() {
    this.api = new HttpOrdersService();
  }

  getOrderTotalizers = (id: string) => {
    return this.api.get(`v1/orders/client/counters?client_id=${id}`);
  };

  getOrders = (
    filter: any,
    page_number: number,
    page_size: number,
    col?: string,
    order?: string
  ) => {
    const url = `v1/orders/search?page_number=${page_number}&page_size=${page_size}`;
    if (col) {
      return this.api.post(
        `${url}&sort_column=${col}&sort_order=${order}`,
        filter
      );
    }
    return this.api.post(url, filter);
  };

  getOrdersFrenet = (filter: {
    client_id: string;
    page_size: number;
    page_number: number;
  }) => {
    const url = `v1/frenet/shipments/list`;
    return this.api.post(url, filter);
  };

  getOrder = (id: string) => {
    return this.api.get(`v1/orders/${id}`);
  };

  getOrdersDrafts = () => {
    return this.api.get('v1/orders/drafts');
  };

  deleteOrders = (ids: string[]) => {
    return this.api.delete('v1/frenet/shipments/batch', {
      ids,
    });
  };

  getFrameworkChecklist = () => {
    return this.api.get('v1/orders/framework_checklist');
  };

  getFrameworkChecklistAnswers = () => {
    return this.api.get('v1/orders/framework_checklist_answers');
  };

  getLabelHTML = (trackingNumber: string) => {
    return this.api.get(`v1/orders/${trackingNumber}/label_raw_html`);
  };

  getLabelessHTML = (id: string) => {
    return this.api.get(`v1/orders/${id}/label_raw_html`);
  };

  postLabelessHMTL = (payload: any) => {
    return this.api.post('v1/orders/labeless/raw_html', payload);
  };

  getTotalStatus = (filter: any) => {
    return this.api.post(`v1/orders/status/counters`, filter);
  };

  getFileStartSignalR = (filter: any) => {
    return this.api.post(`v1/orders/search/export`, filter);
  };

  downloadFile = (values: MessageSignalRReportOrders) => {
    return this.api.getFile(
      `v1/orders/search/download?id=${values.id}&file_date=${values.file_date}&file_name=${values.file_name}`
    );
  };

  getNextStatus = (
    current_status: EnumStateCode,
    scenario: EnumScenario
  ): Promise<GetNextStateResponse> => {
    return this.api.get(
      `v1/orders/next_status?current_status=${current_status}&scenario=${scenario}`
    );
  };

  patchNextStatus = (payload: any) => {
    return this.api.patch(`v1/orders/status`, payload);
  };

  patchDevolution = (payload: any) => {
    return this.api.patch(`v1/orders/devolution`, payload);
  };

  postCancelPayment = (payload: any) => {
    return this.api.post(
      `v1/orders/actions/order_and_payment_canceled`,
      payload
    );
  };

  postCancelPixPayment = (payload: any) => {
    return this.api.post(
      `v1/orders/actions/order_and_pix_payment_canceled`,
      payload
    );
  };

  patchChangePoint = (id: string, payload: any) => {
    return this.api.patch(`v1/orders/${id}/business_unit/drop`, payload);
  };

  postCancelStatus = (id: string, payload: TCancelStatusPayload) => {
    return this.api.post(`v1/orders/${id}/cancel_devolution`, payload);
  };

  postDeliveredBackoffice = (payload: any) => {
    return this.api.post(`v1/orders/actions/delivered_by_backoffice`, payload);
  };

  postReportShopee = (payload: any) => {
    return this.api.post(`v1/platform-orders-fetcher/report`, payload);
  };

  postFileReportShopee = (file: any) => {
    return this.api.postFile(`v1/platform-orders-fetcher/report/upload`, file);
  };

  dropoffByUser = (payload: TDropByBackoffice) => {
    return this.api.post(`v1/orders/actions/dropped_by_client`, payload);
  };

  postLostOrder = (payload: any) => {
    return this.api.post(`v1/orders/lost`, payload);
  };

  postRevertLostOrder = (payload: any) => {
    return this.api.post(`v1/orders/lost/revert`, payload);
  };

  postResendIntegration = (orderId: string) => {
    return this.api.post(`v1/orders/request_carrier/${orderId}/resend`, {});
  };

  searchLabeless = (
    filter: any,
    page_number: number,
    page_size: number,
    col?: string,
    order?: string
  ) => {
    const url = `v1/orders/labeless/search?page_number=${page_number}&page_size=${page_size}`;

    if (col) {
      return this.api.post(
        `${url}&sort_column=${col}&sort_order=${order}`,
        filter
      );
    }
    return this.api.post(url, filter);
  };

  generateLabeless = (payload: any) => {
    return this.api.post(`v1/orders/labeless`, payload);
  };

  postSendIntegrationVesta = (eventId: string) => {
    return this.api.post(`v1/orders/event/${eventId}/send_to_vesta`, {});
  };
}
