import HttpOrdersService from '../../config/HttpOrders';

export class TrackingService {
  api: HttpOrdersService;

  constructor() {
    this.api = new HttpOrdersService();
  }

  getTracking = (id: string) => {
    return this.api.get(`v1/orders/${id}/tracking_info`);
  };
}
