import React from 'react';

function useStickyState(defaultValue: any, key: string) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = sessionStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });
  React.useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

function getStickyState(key: string) {
  const stickyValue = sessionStorage.getItem(key);
  return stickyValue !== null ? JSON.parse(stickyValue) : null;
}

function existsStickyState(key: string) {
  const stickyValue = sessionStorage.getItem(key);
  return stickyValue !== null;
}

export { useStickyState, getStickyState, existsStickyState };
