import { AxiosResponse } from 'axios';
import HttpCarriersService from '../../config/HttpCarriers';
import {
  Carrier,
  CarriersListResponse,
  CreateCarrierPayload,
  SearchCarriersPayload,
  UpdateCarrierPayload,
} from './carriers.type';

export class CarriersService {
  api: HttpCarriersService;

  constructor() {
    this.api = new HttpCarriersService();
  }

  getCarrier = (id: string): Promise<AxiosResponse<Carrier>> => {
    return this.api.get(`v1/carriers/${id}`);
  };

  getActiveCarriers = () => {
    return this.api.get(`v1/carriers`);
  };

  searchCarriers = (
    payload: SearchCarriersPayload
  ): Promise<AxiosResponse<CarriersListResponse>> => {
    return this.api.post(
      `v1/carriers/search?page_number=${payload.page_number}&page_size=${
        payload.page_size
      }&sort_column=${payload.sort_column || ''}&sort_order=${
        payload.sort_order || ''
      }`,
      payload.filter
    );
  };

  createCarrier = (
    payload: CreateCarrierPayload
  ): Promise<AxiosResponse<string>> => {
    return this.api.post(`v1/carriers/`, payload);
  };

  updateCarrier = (
    payload: UpdateCarrierPayload
  ): Promise<AxiosResponse<string>> => {
    return this.api.put(`v1/carriers/${payload.id}`, payload);
  };

  postShippingRates = (name: string, payload: any) => {
    return this.api.post(`v1/carriers/${name}/shipping-rates`, payload);
  };
}
