import { AxiosResponse } from 'axios';
import HttpClientService from '../../config/HttpClient';

export class ClientsService {
  api: HttpClientService;

  constructor() {
    this.api = new HttpClientService();
  }

  getClient = (id: string) => {
    return this.api.get(`v1/clients/${id}`);
  };

  getClientsActives = () => {
    return this.api.get(`v1/clients/actives`);
  };

  getClientsByName = (
    name: string
  ): Promise<
    AxiosResponse<
      {
        id: string;
        full_name: string;
        corporate_name: string;
        trade_name: string;
      }[]
    >
  > => {
    return this.api.post(
      `v1/clients/search_by_name?page_number=${1}&page_size=${50}`,
      {
        name,
      }
    );
  };

  getClientsValidateUser = () => {
    return this.api.get(`v1/clients/get-by-token`);
  };

  postClient = (payload: any) => {
    return this.api.post(`v1/clients`, payload);
  };

  putClient = (payload: any, id: string) => {
    return this.api.put(`v1/clients/${id}`, payload);
  };

  postRecommendation = (payload: any) => {
    return this.api.post(`v1/recommendation`, payload);
  };

  getMarketplacesProductCode = (marketplaceId: string, payload: any) => {
    return this.api.post(
      `v1/marketplaces/${marketplaceId}/product_code/search?page_number=${1}&page_size=${50}`,
      payload
    );
  };

  postMarketplacesProductCode = (marketplaceId: string, payload: any) => {
    return this.api.post(
      `v1/marketplaces/${marketplaceId}/product_code`,
      payload
    );
  };

  deleteMarketplacesProductCode = (
    marketplaceId: string,
    productId: string
  ) => {
    return this.api.delete(
      `v1/marketplaces/${marketplaceId}/product_code/${productId}`
    );
  };

  postFileMarketplacesProductCode = (marketplaceId: string, file: any) => {
    return this.api.postFile(
      `v1/marketplaces/${marketplaceId}/product_code/importer`,
      file
    );
  };

  getMarketplacesInclusiveBusinessUnit = (businessUnitId: string) => {
    return this.api.get(
      `v1/marketplaces/inclusive_business_unit/business_unit/${businessUnitId}`
    );
  };

  getMarketplaceInclusiveBusinessUnit = (marketplaceId: string) => {
    return this.api.get(
      `v1/marketplaces/${marketplaceId}/inclusive_business_unit?page_number=${1}&page_size=${50}`
    );
  };
}
