import * as Yup from 'yup';
import { VoucherCategory, VoucherRestrictionType } from '../../enum';

const validationSchemaCreate = Yup.object().shape({
  name: Yup.string().required('obrigatório'),
  category: Yup.number().required('obrigatório'),
  start_date: Yup.string().required('obrigatório'),
  end_date: Yup.string().required('obrigatório'),
  type: Yup.string().required('obrigatório'),
  value: Yup.string().required('obrigatório'),
  restriction_type: Yup.number().required('obrigatório'),
  active: Yup.boolean().nullable().required('obrigatório'),
  clients: Yup.array().when('category', {
    is: VoucherCategory.Client,
    then: Yup.array().min(1, 'Obrigatório'),
  }),
  states: Yup.array().when('category', {
    is: VoucherCategory.Region,
    then: Yup.array().min(1, 'Obrigatório'),
  }),
  restriction: Yup.string().when('restriction_type', {
    is: VoucherRestrictionType.Limited,
    then: Yup.string().required('Obrigatório'),
  }),
});

export default validationSchemaCreate;
