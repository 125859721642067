import { ReactElement, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import { TypeErrorTreatment } from '../../utils';
import {
  EnumAlert,
  VoucherCategory,
  VoucherRestrictionType,
  VoucherType,
} from '../../enum';
import Alert from '../../components/Alert';
import Table from '../../components/Tables/Table';
import Pagination from '../../components/Pagination';
import Filter, { FilterVoucherProps } from './components/filter';
import { VaucherService } from '../../service';
import { page_size } from '../../constants/pageSize';
import { VoucherCategoryOptions } from '../../enum/optionsEnum';

const header = [
  'Código Cupom de Desconto',
  'Categoria',
  'Período',
  'Status',
  'Detalhes',
];
const attributes = [
  'name',
  'categoryLabel',
  'period',
  'activeLabel',
  'details',
];

type VoucherListProps = {
  id: string;
  name: string;
  category: VoucherCategory;
  clients: string[];
  states: string[];
  type?: VoucherType;
  value: string;
  restriction_type?: VoucherRestrictionType;
  restriction: string;
  active?: boolean;
  start_date: string;
  end_date: string;
  activeLabel: string;
  categoryLabel: string;
  period: string;
  details: ReactElement;
};

const Voucher = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const vaucherService = new VaucherService();
  const [errorsBack, setErrorsBack] = useState<TypeErrorTreatment[]>([]);
  const [dataRequests, setDataRequests] = useState<VoucherListProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<FilterVoucherProps>({});
  const [page, setPage] = useState({
    totalRecords: 1,
    currentPageNumber: 1,
  });

  const treatment = (data: VoucherListProps[]) => {
    const model: VoucherListProps[] = [];
    data.forEach((item) => {
      model.push({
        ...item,
        activeLabel: item?.active ? 'Ativo' : 'Inativo',
        categoryLabel:
          VoucherCategoryOptions.find((x) => x.value === item.category)
            ?.label || '',
        period: `${new Date(item.start_date).toLocaleDateString(
          'pt-BR'
        )} até ${new Date(item.end_date).toLocaleDateString('pt-BR')}`,
        details: (
          <Button
            type="button"
            variant="link"
            title="Ver"
            onClick={() => history.push(`/voucher/details/${item.id}`)}
            className="link-success p-0"
          >
            <i className="uil uil-search-plus ft-size-1" />
          </Button>
        ),
      });
    });

    setDataRequests(model);
  };

  const getValuesPanel = (filters: FilterVoucherProps, currentPage = 1) => {
    setLoading(true);
    setDataRequests([]);
    vaucherService
      .getVouchers(filters, currentPage, page_size)
      .then((resp: any) => {
        setPage({
          totalRecords: resp.data.totalRecords,
          currentPageNumber: resp.data.currentPageNumber,
        });
        treatment(resp?.data?.data || []);
      })
      .catch(() => {
        setPage({
          totalRecords: 0,
          currentPageNumber: 1,
        });
        setDataRequests([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatingFilter = (filters: FilterVoucherProps) => {
    const auxFilters = { ...filters };
    if (auxFilters?.name === '') delete auxFilters.name;
    if (auxFilters?.start_date === '') delete auxFilters.start_date;
    if (auxFilters?.end_date === '') delete auxFilters.end_date;

    setFilter(auxFilters);
    getValuesPanel(auxFilters);
  };

  const updatingPage = (currentPage: number) => {
    getValuesPanel(filter, currentPage);
  };

  useEffect(() => {
    getValuesPanel(filter);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row>
        {errorsBack &&
          errorsBack.map((error) => (
            <Col xl={{ span: 6, offset: 3 }} md={12} key={error.message}>
              <Alert
                type={EnumAlert.Error}
                text={t(error.message)}
                remove={() => {
                  const arrayError = errorsBack.filter(
                    (x) => x.message !== error.message
                  );
                  setErrorsBack(arrayError);
                }}
              />
            </Col>
          ))}
      </Row>

      <Row>
        <Col md={12}>
          <div className="d-flex justify-content-between page-title-box">
            <h4 className="page-title">Cupom de Desconto</h4>
            <Button
              variant="secondary"
              className="btn-return"
              onClick={() => {
                history.push('/voucher/create');
              }}
            >
              Adicionar
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Filter getValues={updatingFilter} loading={loading} />

              <Table
                header={header}
                attributes={attributes}
                data={dataRequests}
                loading={loading}
              />
              <Row>
                <Col sm={12} md={8}>
                  <Pagination
                    updatingPage={updatingPage}
                    {...page}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Voucher;
