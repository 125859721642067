// @flow
import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Routes from './routes/Routes';

import './assets/scss/Saas.scss';

import { GET_CART } from './redux/cart/reducers';
import { Environment, getCurrentApiEnv } from './config/Environment';

const App = (): ReactElement<any> => {
  const dispatchCart = useDispatch();
  useEffect(() => {
    dispatchCart({ type: GET_CART });
  });

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {Environment.baseUrl[getCurrentApiEnv()].scriptClarity}
        </script>
      </Helmet>
      <Routes />
    </>
  );
};

export default App;
