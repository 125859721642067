import { AxiosResponse } from 'axios';
import HttpBusinessUnitsService from '../../config/HttpBusinessUnits';
import {
  PudosByCode,
  RequestData,
  TAddressBUnit,
  TChangeLogin,
} from './business.type';

export class BusinessUnitsService {
  api: HttpBusinessUnitsService;

  constructor() {
    this.api = new HttpBusinessUnitsService();
  }

  get = (postalCode: string, maxDistanceInMeters = 50000) => {
    return this.api.get(
      `v1/business-units/nearby/${postalCode}?max_distance_in_meters=${maxDistanceInMeters}`
    );
  };

  getBusinessById = (id: string) => {
    return this.api.get(`v1/business-units/${id}`);
  };

  getTicketsByIdOrder = (id: string) => {
    return this.api.get(`v1/tickets/order_id/${id}`);
  };

  getPudo = (id: string) => {
    return this.api.get(`v1/business-units/${id}`);
  };

  getListPudo = (ids: string[]) => {
    return this.api.post(`v1/business-units/list`, { ids });
  };

  getPudoAll = (code: string) => {
    return this.api.post(
      `v1/business-units/search?page_number=${1}&page_size=${50}`,
      {
        code,
      }
    );
  };

  setShopeeToken = (id: string, platform: string, payload: any) => {
    return this.api.patch(
      `v1/business-units/${id}/platform_order_fetcher_login/${platform}/token`,
      payload
    );
  };

  getPudos = (
    filter: any,
    page_number: number,
    page_size: number,
    col?: string,
    order?: string
  ) => {
    const url = `v1/business-units/search?page_number=${page_number}&page_size=${page_size}`;
    if (col) {
      return this.api.post(
        `${url}&sort_column=${col}&sort_order=${order}`,
        filter
      );
    }
    return this.api.post(url, filter);
  };

  getPudosByName = (
    name: string,
    accept_reverse_labeless: boolean | null = null
  ) => {
    const requestData: RequestData = {
      name,
    };

    // Verifica se accept_reverse_labeless não é nulo antes de adicioná-lo aos dados da requisição
    if (accept_reverse_labeless !== null) {
      requestData.accept_reverse_labeless = accept_reverse_labeless;
    }

    return this.api.post(
      `v1/business-units/search?page_number=${1}&page_size=${50}`,
      requestData
    );
  };

  getPudosByCode = (
    code: string,
    accept_reverse_labeless: boolean | null = null
  ) => {
    const requestData: RequestData = {
      code,
    };

    // Verifica se accept_reverse_labeless não é nulo antes de adicioná-lo aos dados da requisição
    if (accept_reverse_labeless !== null) {
      requestData.accept_reverse_labeless = accept_reverse_labeless;
    }

    return this.api.post(
      `v1/business-units/search?page_number=${1}&page_size=${50}`,
      requestData
    );
  };

  getBusinessByCode = (code: string) => {
    return this.api.get(`v1/business-units/${code}/recommendation`);
  };

  putBusiness = (id: string, payload: any) => {
    return this.api.put(`v1/business-units/${id}/backoffice`, payload);
  };

  searchBusinessUnitsByCode = (payload: {
    codes: string[];
  }): Promise<AxiosResponse<PudosByCode[]>> => {
    return this.api.post(`v1/business-units/get_by_codes`, payload);
  };

  searchListPudos = (filter: any, page_number: number, page_size: number) => {
    return this.api.post(
      `v1/business-units/search_nearby?page_number=${page_number}&page_size=${page_size}`,
      filter
    );
  };

  patchBusinessUnitsActivation = (payload: any) => {
    return this.api.patch(`v1/business-units/activation`, payload);
  };

  patchBusinessUnitsAcceptingOrders = (payload: any) => {
    return this.api.patch(`v1/business-units/accepting_orders`, payload);
  };

  patchBusinessUnitsHidden = (payload: any) => {
    return this.api.patch(`v1/business-units/hidden`, payload);
  };

  downloadBusinessUnitsTemplate = () => {
    return this.api.getFile('v1/business-units/import/template');
  };

  uploadBusinessUnitsTemplate = (file: any) => {
    return this.api.postFile('v1/business-units/import/upload', file);
  };

  getBusinessUnitsTemplateList = () => {
    return this.api.get('v1/business-units/import/list');
  };

  deleteBusinessUnitImport = (id: string) => {
    return this.api.delete(`v1/business-units/import/${id}`);
  };

  getBusinessUnitImport = (id: string) => {
    return this.api.get(`v1/business-units/import/${id}`);
  };

  putBusinessUnitImport = (id: string, payload: any) => {
    return this.api.put(`v1/business-units/import/${id}`, payload);
  };

  postBusinessUnitImportComplet = () => {
    return this.api.post(`v1/business-units/import/complete`, {});
  };

  putBusinessUnitAddress = (bunitId: string, payload: TAddressBUnit) => {
    return this.api.put(`v1/business-units/${bunitId}/address`, payload);
  };

  patchBusinessUnitLogin = (bunitId: string, payload: TChangeLogin) => {
    return this.api.patch(`v1/business-units/${bunitId}/change_login`, payload);
  };

  searchListOperators = (
    filter: any,
    page_number: number,
    page_size: number,
    col?: string,
    order?: string
  ) => {
    const url = `v1/business-units/operator/search?page_number=${page_number}&page_size=${page_size}`;
    if (col) {
      return this.api.post(
        `${url}&sort_column=${col}&sort_order=${order}`,
        filter
      );
    }
    return this.api.post(url, filter);
  };

  getOperator = (id: string) => {
    return this.api.get(`v1/business-units/operator/${id}`);
  };

  postOperator = (id: string, payload: any) => {
    return this.api.post(
      `v1/business-units/business_unit/${id}/operator`,
      payload
    );
  };

  putOperator = (id: string, payload: any) => {
    return this.api.put(`v1/business-units/operator/${id}`, payload);
  };

  patchOperatorStatus = (payload: any) => {
    return this.api.patch(
      `v1/business-units/operator/activation_status`,
      payload
    );
  };

  searchListContract = (
    filter: any,
    page_number: number,
    page_size: number,
    col?: string,
    order?: string
  ) => {
    const url = `v1/prospect/contract_template/search?page_number=${page_number}&page_size=${page_size}`;
    if (col) {
      return this.api.post(
        `${url}&sort_column=${col}&sort_order=${order}`,
        filter
      );
    }

    return this.api.post(url, filter);
  };

  getContract = (id: string) => {
    return this.api.get(`v1/prospect/contract_template/${id}`);
  };

  postContract = (payload: any) => {
    return this.api.post('v1/prospect/contract_template/', payload);
  };

  patchContract = (id: string, payload: any) => {
    return this.api.patch(
      `v1/prospect/contract_template/${id}/status`,
      payload
    );
  };
}
