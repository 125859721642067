import moment from 'moment';

const FormatDateUtc = (date: any, format = 'DD/MM/YYYY HH:mm:ss') => {
  return date !== ''
    ? moment.utc(date).local().format(format)
    : 'Data não informada';
};

const dateFormatToTimezone = (date: string, showSeconds = false) => {
  if (date || date !== '') {
    const offset = new Date().getTimezoneOffset();
    const formatted = -(offset / 60);
    const formattedDate = new Date(date);
    formattedDate.setHours(formattedDate.getHours() + formatted);

    if (showSeconds) return new Date(formattedDate).toLocaleString();

    return new Date(formattedDate).toLocaleString().slice(0, -3);
  }

  return 'Data não informada';
};

const subtractHoursAndFormat = (dateString: string): string => {
  if (dateString && dateString !== '') {
    const originalDate = new Date(dateString);
    const adjustedDate = new Date(originalDate.getTime());

    const day = adjustedDate.getDate().toString().padStart(2, '0');
    const month = (adjustedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = adjustedDate.getFullYear();
    const hoursFormatted = adjustedDate.getHours().toString().padStart(2, '0');
    const minutes = adjustedDate.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year}, ${hoursFormatted}:${minutes}`;
  }

  return 'Data não informada';
};

const formatedMoney = new Intl.NumberFormat('pt-br', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
});

const formatCEP = (oldCep: string): string => {
  const cep = `000${oldCep}`.slice(-8);
  const regex = /^([\d]{2}).?([\d]{3})-?([\d]{3})/;

  if (regex.test(cep)) {
    return cep.replace(regex, '$1.$2-$3');
  }

  return '';
};

const formatCpf = (value: string): string => {
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};

const formatCnpj = (value: string): string => {
  return value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5'
  );
};

const formatCpfOrCnpj = (textField: string | undefined): string => {
  if (textField) {
    if (textField.length <= 11) {
      textField = formatCpf(textField);
    } else {
      textField = formatCnpj(textField);
    }

    return textField;
  }

  return 'Não informado';
};

const formatPhone = (v: string): string => {
  if (v && v.length === 11 && v[2] === '9') {
    return v.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3');
  }
  return v.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3');
};

const extractValueObjects = (array: any, key: string) => {
  return array.map((element: any) => element[key]);
};

const createUUID = () => {
  function random() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${random()}${random()}-${random()}-${random()}-${random()}-${random()}${random()}${random()}`;
};

const removeElement = (array: any, key: string, someValue: any) => {
  return array.filter((item: any) => item[key] !== someValue);
};

const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result.replace(/^data:.+;base64,/, '');
      resolve(base64);
    };
    reader.onerror = (error: any) => reject(error);
  });

function getFiles(_files: any) {
  return Promise.all(_files.map((file: any) => toBase64(file)));
}

const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const isNotBoolean = (value: any) => {
  return typeof value !== 'boolean';
};

function validarCPF(cpf: string): boolean {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '' || cpf.length !== 11) return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += Number(cpf.charAt(i)) * (10 - i);  
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== Number(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += Number(cpf.charAt(i)) * (11 - i);  
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== Number(cpf.charAt(10))) return false;
  return true;
}

function validarCNPJ(cnpj: string): boolean {
  cnpj = cnpj.replace(/[^\d]+/g, '');
  if (cnpj === '') return false;
  if (cnpj.length !== 14) return false;

  // Elimina CNPJs inválidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return false;
  }

  // Valida DVs
  let tamanho = cnpj.length - 2; // 12
  let numeros = cnpj.substring(0, tamanho); // 101293000001
  const digitos = cnpj.substring(tamanho); // 29
  let soma = 0;
  let pos = tamanho - 7; // 5

  for (let i = tamanho; i >= 1; i -= 1) {
    soma += Number(numeros.charAt(tamanho - i)) * pos;
    pos -= 1;
    if (pos < 2) {
      pos = 9;
    }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0), 10)) {
    return false;
  }

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i -= 1) {
    soma += Number(numeros.charAt(tamanho - i)) * pos;
    pos -= 1;
    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(1), 10)) {
    return false;
  }

  return true;
}

const removeCharacters = (value: string) => {
  return value.replace(/[.\-/()\sR$]/g, '');
};

export {
  FormatDateUtc,
  formatedMoney,
  formatCEP,
  formatCpf,
  formatCnpj,
  formatCpfOrCnpj,
  formatPhone,
  extractValueObjects,
  createUUID,
  removeElement,
  dateFormatToTimezone,
  subtractHoursAndFormat,
  getFiles,
  capitalizeFirstLetter,
  isNotBoolean,
  validarCPF,
  validarCNPJ,
  removeCharacters,
};
