enum EnumAlert {
  Error = 1,
  Warning = 2,
  Success = 3,
}

enum EnumStateCode {
  Draft = 1,
  Ready = 2,
  Canceled = 3,
  Expired = 4,
  Dropped = 5,
  InTransit = 6,
  Delivered = 7,
  ToCollect = 8,
  CollectedByCarrier = 9,
  DevolutionDropped = 10,
  OrderReturned = 11,
  ProcessingDrop = 13,
  Lost = 14,
}

enum OrderPaymentCombinedStatus {
  PendingPayment = 1, // [Description("Pendente Pagamento")]
  PaymentUnderAnalysis = 2, // [Description("Pagamento em Análise")]
  PaymentRejected = 3, // [Description("Pagamento Rejeitado")]
  Canceled = 4, // [Description("Cancelado")]
  WaitingForDrop = 5, // [Description("Aguardando Drop")]
  Expired = 6, // [Description("Expirado")]
  Reversed = 7, // [Description("Estornado")]
  Dropped = 8, // [Description("Dropado")]
  InDelivery = 9, // [Description("Em Entrega")]
  Delivered = 10, // [Description("Entregue")]
  CollectedByCarrier = 11, // [Description("Coletado Transportadora")]
  ToCollect = 12, // [Description("A coletar")]
  DroppedReturn = 13, // [Description("Devolução Droppada")]
  ReturnedOrder = 14, // [Description("Encomenda Devolvida")]
}

enum EnumDeliveryType {
  Address = 1,
  Point = 2,
}

enum EnumTypePerson {
  Physical = 1,
  Juridic = 2,
}

enum EnumDocumentType {
  Invoice = 1,
  Declaration = 2,
}

enum EnumTypeAddress {
  Sender = 1,
  Receiver = 2,
}

enum EnumTypeShipping {
  Customer = 1,
  BusinessUnit = 2,
}

enum EnumTransportStatus {
  Delivered,
  InTransit,
  PendingCollect,
  PendingSend,
  Received,
  ToReceive,
}

enum EnumServiceType {
  Reverse = 1,
  Delivery = 2,
  Devolution = 3,
}

enum EnumOperationType {
  FirstMile = 1,
  LastMile = 2,
}

enum EnumEvent {
  Create = 1,
  DroppedByClient = 2,
  DeliveredToCarrier = 3,
  DeliveredToRecipient = 4,
  PaymentApproved = 5,
  CarrierDeliveredToRecipient = 6,
  SellerLabelSentByEmail = 7,
  BarcodeSentByEmail = 8,
  CarrierRequested = 9,
  StuckInDropped = 10,
  StuckInReady = 11,
  PaymentCanceledBeforeDrop = 12,
  DroppedByCarrier = 13,
  UpdatePaymentStatus = 14,
  CarrierInTransit = 15,
  OrderCanceled = 16,
  StuckInDraft = 17,
  DevolutionDropped = 18,
  OrderReturned = 19,
  StatusChangedByUser = 20,
  DropOffBusinessUnitChangedByUser = 21,
  OrderCanceledByUser = 22,
  CanceledByCarrier = 23,
  DeliveredToDistributionCenter = 24,
  DevolutionCanceled = 25,
  RemovedFromPlatformPickup = 26,
  OrderDropProcessing = 29,
  OrderLost = 30,
  OrderLossReverted = 31,
}

enum EnumScenario {
  Scenario1 = 1, // tem transportadora
  Scenario2 = 2, // não tem transportadora
  Scenario3 = 3,
  Scenario4 = 4,
  Scenario5 = 5,
  Scenario6 = 6,
}

enum LegalEntityType {
  ME = 1,
  EPP = 2,
  MEI = 3,
  None = 4,
}

enum EnumPaymentStatus {
  None = 0,
  Pending = 1,
  Approved = 2,
  Canceled = 3,
  Rejected = 4,
  Reversal = 5,
  Expired = 6,
}

enum VoucherRestrictionType {
  Limited = 1,
  Unlimited = 2,
}
enum VoucherType {
  Fixed = 1,
  Percentual = 2,
}
enum VoucherCategory {
  Client = 1,
  Region = 2,
  All = 3,
}

enum AccountType {
  ContaCorrente = 1,
  Poupança = 2,
}

enum PixType {
  Document = 1,
  Email = 2,
  Phone = 3,
  Random = 4,
}

enum CreatedByType {
  Marketplace = 1,
  Client = 2,
}

enum UserLoginType {
  BusinessUnitId = 1,
  ClientId = 2,
  MarketplaceId = 3,
  UserId = 4,
}

enum DateOfType {
  Created = 1,
  DropOff = 2,
  Delivered = 3,
}

enum Trigger {
  PaymentApproved = 1,
  DroppedByClient = 2,
  StuckInDropped = 3,
  StuckInReady = 4,
  DeliveredToRecipient = 5,
  DeliveredToCarrier = 6,
  PaymentCanceledBeforeDrop = 7,
  CarrierDeliveredToRecipient = 8,
  DroppedByCarrier = 9,
  ToCollect = 10,
  CarrierInTransit = 11,
  OrderCanceled = 12,
  StuckInDraft = 13,
  DeliveredToCarrierNotIntegrated = 14,
  DevolutionDropped = 15,
  OrderReturned = 16,
}

enum CarrierStatusCode {
  None = 1,
  Requested = 2,
  InProgress = 3,
  Completed = 4,
  Collected = 5,
  Canceled = 6,
}

enum EnumPaymentType {
  None = 0,
  PaymentSlip = 1,
  BankTransfer = 2,
  CreditCard = 3,
  PIX = 4,
}

enum EnumScenarioCase {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

enum EnumAuthType {
  Basic = 1,
  ApiKey = 2,
  Bearer = 3,
}

enum EnumHttpMethod {
  Get = 1,
  Post = 2,
  Put = 3,
  Delete = 4,
  Patch = 5,
  Options = 6,
  Head = 7,
}

enum EnumRequestType {
  app = 1,
  web = 2,
}

enum ProspectPhoto {
  // "RG/CNH Frente"
  IdentificationDocumentFront = 1,
  // "RG/CNH Verso"
  IdentificationDocumentBack = 2,
  // "Foto da fachada"
  BusinessUnitFront = 3,
  // "Foto do interior"
  BusinessUnitInterior = 4,
  // "Foto da rua"
  BusinessUnitStreet = 5,
  // "Selfie"
  Selfie = 6,
}

enum EnumReverseType {
  None = 0,
  Normal = 1,
  Labeless = 2,
}

enum EnumSignatoryOrder {
  Name = 1,
  ModelId = 2,
  Id = 3,
  Qualification = 4,
  CreatedDate = 5,
}

enum EnumStateCodeFrenet {
  Unknown = 0,
  Criado = 1,
  PendentePagamento = 2,
  FalhaPagamento = 3,
  SucessoPagamento = 4,
  Postado = 5,
  CancelamentoAgendado = 6,
  Cancelado = 7,
  Excluido = 9,
}

enum EnumCarrierIntegration {
  Nenhum = 0,
  SFX = 1,
  Vesta = 2, 
}

export {
  EnumAlert,
  EnumDeliveryType,
  EnumTypePerson,
  EnumDocumentType,
  EnumTransportStatus,
  EnumTypeAddress,
  EnumTypeShipping,
  EnumStateCode,
  EnumEvent,
  EnumServiceType,
  EnumScenario,
  LegalEntityType,
  EnumPaymentStatus,
  VoucherRestrictionType,
  VoucherType,
  VoucherCategory,
  AccountType,
  PixType,
  CreatedByType,
  UserLoginType,
  DateOfType,
  OrderPaymentCombinedStatus,
  Trigger,
  CarrierStatusCode,
  EnumPaymentType,
  EnumScenarioCase,
  EnumAuthType,
  EnumHttpMethod,
  EnumOperationType,
  EnumRequestType,
  ProspectPhoto,
  EnumReverseType,
  EnumSignatoryOrder,
  EnumStateCodeFrenet,
  EnumCarrierIntegration,
};
