import { ReactElement, MouseEventHandler } from 'react';
import { EnumAlert } from '../../enum';

type AlertProps = {
  type: EnumAlert;
  title?: string;
  text: string;
  remove: MouseEventHandler<HTMLButtonElement>;
};

const Alert = ({ type, title, text, remove }: AlertProps): ReactElement => {
  if (type === EnumAlert.Error) {
    return (
      <div className="fade alert alert-danger alert-dismissible show">
        <button
          type="button"
          onClick={remove}
          className="btn-close"
          aria-label="Close alert"
        />
        {title && <strong>{title}</strong>} {text}
      </div>
    );
  }

  if (type === EnumAlert.Warning) {
    return (
      <div className="fade alert alert-warning alert-dismissible show">
        <button
          type="button"
          onClick={remove}
          className="btn-close"
          aria-label="Close alert"
        />
        {title && <strong>{title}</strong>} {text}
      </div>
    );
  }

  return (
    <div className="fade alert alert-success alert-dismissible show">
      <button
        type="button"
        onClick={remove}
        className="btn-close"
        aria-label="Close alert"
      />
      {title && <strong>{title}</strong>} {text}
    </div>
  );
};

export default Alert;
