/**
 * Configuração das rotas do APP para comunicação com a API
 */

export const getCurrentApiEnv = () => {
  if (process.env.REACT_APP_ENV === 'local') return 'local';
  if (process.env.REACT_APP_ENV === 'homolog') return 'homolog';
  if (process.env.REACT_APP_ENV === 'production') return 'production';
  if (process.env.REACT_APP_ENV === 'NewDevelop') return 'NewDevelop';
  if (process.env.REACT_APP_ENV === 'NewHomolog') return 'NewHomolog';
  return 'dev';
};

export const Environment = {
  baseUrl: {
    local: {
      urlApi: 'https://app-drops-clients-api-dev.azurewebsites.net/api/',
      urlPostalCode:
        'https://func-drops-postal-code-dev.azurewebsites.net/api/',
      urlOrders: 'https://app-drops-order-api-dev.azurewebsites.net/api/',
      urlCarriers: 'https://app-drops-carriers-api-dev.azurewebsites.net/api/',
      urlPayments: 'https://app-drops-payment-api-dev.azurewebsites.net/api/',
      urlUsers: 'https://app-drops-users-api-dev.azurewebsites.net/api/',
      urlBusinessUnit:
        'https://app-drops-businessunits-api-dev.azurewebsites.net/api/',
      auth0ClientId: 'NxgQa8MjfoyPF2J4yE5XmXldIelsDM8i',
      auth0Domain: 'pudo-dev.us.auth0.com',
      auth0Audience: 'https://apis/users-api',
      funcPostalCode: {
        url: 'https://func-drops-postal-code-dev.azurewebsites.net/api/',
        code: 'GTHxoXAz0hS4L2ej/XZfz1GZ4l12IDMfyVDnGAZQQSLbmVnCIct16w==',
      },
      funcSignalR: {
        url: 'https://func-drops-shared-service-signalr-dev.azurewebsites.net/api/',
        code: 'eSRuFMithnEr1m4Mz0Sll1Hxf5Fz1wS85gt5dCM2JjSxAzFuk1DiGw==',
      },
      resources: {
        exportReport: true,
      },
      funcProspection:
        'https://func-drops-businessunit-prospect-dev.azurewebsites.net/api/add-prospect',
      scriptClarity: `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "n0jod49n7q");
      `,
    },
    dev: {
      urlApi: 'https://app-pudo-clients-api-dev.azurewebsites.net/api/',
      urlPostalCode: 'https://func-pudo-postalcode-dev.azurewebsites.net/',
      urlOrders: 'https://app-pudo-orders-api-dev.azurewebsites.net/api/',
      urlCarriers: 'https://app-pudo-carriers-api-dev.azurewebsites.net/api/',
      urlPayments: 'https://app-pudo-payments-api-dev.azurewebsites.net/api/',
      urlUsers: 'https://app-pudo-users-api-dev.azurewebsites.net/api/',
      urlBusinessUnit:
        'https://app-pudo-businessunits-api-dev.azurewebsites.net/api/',
      auth0ClientId: 'NxgQa8MjfoyPF2J4yE5XmXldIelsDM8i',
      auth0Domain: 'pudo-dev.us.auth0.com',
      auth0Audience: 'https://apis/users-api',
      funcPostalCode: {
        url: 'https://func-pudo-postalcode-dev.azurewebsites.net/api/',
        code: 'euDmMqf1aZFz4PzV7coboOzrzCZzWtmOPlobkPIpXjSsj0AQMTJarg==',
      },
      funcSignalR: {
        url: 'https://func-pudo-shared-signalr-dev.azurewebsites.net/api/',
        code: 'DvemEw0mOzSvUqwpdxfNUkTF5jk5iiPnggM01cG-TE-vAzFuI37U3Q==',
      },
      resources: {
        exportReport: true,
      },
      funcProspection:
        'https://func-drops-businessunit-prospect-dev.azurewebsites.net/api/add-prospect',
      scriptClarity: `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "n0jod49n7q");
      `,
    },
    NewDevelop: {
      urlApi: 'https://app-drops-clients-api-dev.azurewebsites.net/api/',
      urlPostalCode:
        'https://func-drops-postal-code-dev.azurewebsites.net/api/',
      urlOrders: 'https://app-drops-order-api-dev.azurewebsites.net/api/',
      urlCarriers: 'https://app-drops-carriers-api-dev.azurewebsites.net/api/',
      urlPayments: 'https://app-drops-payment-api-dev.azurewebsites.net/api/',
      urlUsers: 'https://app-drops-users-api-dev.azurewebsites.net/api/',
      urlBusinessUnit:
        'https://app-drops-businessunits-api-dev.azurewebsites.net/api/',
      auth0ClientId: 'NxgQa8MjfoyPF2J4yE5XmXldIelsDM8i',
      auth0Domain: 'pudo-dev.us.auth0.com',
      auth0Audience: 'https://apis/users-api',
      funcPostalCode: {
        url: 'https://func-drops-postal-code-dev.azurewebsites.net/api/',
        code: 'GTHxoXAz0hS4L2ej/XZfz1GZ4l12IDMfyVDnGAZQQSLbmVnCIct16w==',
      },
      funcSignalR: {
        url: 'https://func-drops-shared-service-signalr-dev.azurewebsites.net/api/',
        code: 'eSRuFMithnEr1m4Mz0Sll1Hxf5Fz1wS85gt5dCM2JjSxAzFuk1DiGw==',
      },
      resources: {
        exportReport: true,
      },
      funcProspection:
        'https://func-drops-businessunit-prospect-dev.azurewebsites.net/api/add-prospect',
      scriptClarity: `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "n0jod49n7q");
      `,
    },
    homolog: {
      urlApi: 'https://app-pudo-clients-api-hml.azurewebsites.net/api/',
      urlPostalCode: 'https://func-pudo-postalcode-hml.azurewebsites.net/api/',
      urlOrders: 'https://app-pudo-orders-api-hml.azurewebsites.net/api/',
      urlCarriers: 'https://app-pudo-carriers-api-hml.azurewebsites.net/api/',
      urlPayments: 'https://app-pudo-payments-api-hml.azurewebsites.net/api/',
      urlUsers: 'https://app-pudo-users-api-hml.azurewebsites.net/api/',
      urlBusinessUnit:
        'https://app-pudo-businessunits-api-hml.azurewebsites.net/api/',
      auth0ClientId: 'nbz99reDw6zQgR26nuGWj0jiVG01TacF',
      auth0Domain: 'login-hml.pontodrops.com.br',
      auth0Audience: 'https://apis/users-api',
      funcPostalCode: {
        url: 'https://func-pudo-postalcode-hml.azurewebsites.net/api/',
        code: 'GTHxoXAz0hS4L2ej/XZfz1GZ4l12IDMfyVDnGAZQQSLbmVnCIct16w==',
      },
      funcSignalR: {
        url: 'https://func-pudo-shared-signalr-hml.azurewebsites.net/api/',
        code: 'eSRuFMithnEr1m4Mz0Sll1Hxf5Fz1wS85gt5dCM2JjSxAzFuk1DiGw==',
      },
      resources: {
        exportReport: true,
      },
      funcProspection:
        'https://func-drops-businessunit-prospect-hml.azurewebsites.net/api/add-prospect',
      scriptClarity: `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "n0jod49n7q");
      `,
    },
    NewHomolog: {
      urlApi: 'https://app-drops-clients-api-hml.azurewebsites.net/api/',
      urlPostalCode:
        'https://func-drops-postal-code-hml.azurewebsites.net/api/',
      urlOrders: 'https://app-drops-order-api-hml.azurewebsites.net/api/',
      urlCarriers: 'https://app-drops-carriers-api-hml.azurewebsites.net/api/',
      urlPayments: 'https://app-drops-payment-api-hml.azurewebsites.net/api/',
      urlUsers: 'https://app-drops-users-api-hml.azurewebsites.net/api/',
      urlBusinessUnit:
        'https://app-drops-businessunits-api-hml.azurewebsites.net/api/',
      auth0ClientId: 'nbz99reDw6zQgR26nuGWj0jiVG01TacF',
      auth0Domain: 'login-hml.pontodrops.com.br',
      auth0Audience: 'https://apis/users-api',
      funcPostalCode: {
        url: 'https://func-drops-postal-code-hml.azurewebsites.net/api/',
        code: 'GTHxoXAz0hS4L2ej/XZfz1GZ4l12IDMfyVDnGAZQQSLbmVnCIct16w==',
      },
      funcSignalR: {
        url: 'https://func-drops-shared-service-signalr-hml.azurewebsites.net/api/',
        code: 'eSRuFMithnEr1m4Mz0Sll1Hxf5Fz1wS85gt5dCM2JjSxAzFuk1DiGw==',
      },
      resources: {
        exportReport: true,
      },
      funcProspection:
        'https://func-drops-businessunit-prospect-hml.azurewebsites.net/api/add-prospect',
      scriptClarity: `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "n0jod49n7q");
      `,
    },
    production: {
      urlApi: 'https://clients-api.pontodrops.com.br/api/',
      urlPostalCode: 'https://postalcode.pontodrops.com.br/api/',
      urlOrders: 'https://orders-api.pontodrops.com.br/api/',
      urlCarriers: 'https://carriers-api.pontodrops.com.br/api/',
      urlPayments: 'https://payments-api.pontodrops.com.br/api/',
      urlUsers: 'https://users-api.pontodrops.com.br/api/',
      urlBusinessUnit: 'https://businessunits-api.pontodrops.com.br/api/',
      auth0ClientId: '3h6oPhEVD3wJKAZrItm5D83HPkOBmlNC',
      auth0Domain: 'login.pontodrops.com.br',
      auth0Audience: 'https://apis/users-api',
      funcPostalCode: {
        url: 'https://postalcode.pontodrops.com.br/api/',
        code: 'wik0dMBik0BQX8V39QTUpqRMyzVUd1HcqS29MUWDQkJI1l1AbeVs3w==',
      },
      funcSignalR: {
        url: 'https://signalr.pontodrops.com.br/api/',
        code: 't2fgh6TJCAVkE_bzNc5gwpOZgatjhTku2DAUe43HRqOZAzFuxOZ7bQ==',
      },
      resources: {
        exportReport: false,
      },
      funcProspection:
        'https://func-drops-businessunit-prospect-prd.azurewebsites.net/api/add-prospect',
      scriptClarity: `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "n0it5b19ut");
      `,
    },
  },
};
