// @flow
import React, { useState } from 'react';
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import classNames from 'classnames';

/* Password Input */
const PasswordInput = ({
  name,
  placeholder,
  refCallback,
  errors,
  register,
  className,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <InputGroup className="mb-0">
        <Form.Control
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          name={name}
          id={name}
          onChange={onChange}
          as="input"
          ref={(r) => {
            if (refCallback) refCallback(r);
          }}
          className={className}
          isInvalid={errors ? true : false}
          {...(register ? register(name) : {})}
          autoComplete={name}
        />
        <div
          className={classNames('input-group-text', 'input-group-password', {
            'show-password': showPassword,
          })}
          data-password={showPassword ? 'true' : 'false'}
        >
          <span
            className="password-eye"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          ></span>
        </div>
      </InputGroup>
    </>
  );
};

type FormInputProps = {
  label?: string,
  tooltipText?: string,
  type?: string,
  name?: string,
  placeholder?: string,
  register?: any,
  errors?: any,
  className?: string,
  labelClassName?: string,
  containerClass?: string,
  refCallback?: any,
  children?: any,
  disabled?: Boolean,
  onChange?: any,
  onKeyPress?: any,
  onClick?: any,
  onClickButton?: any,
  required?: Boolean,
  value?: any,
  checked?: any,
  editIcon?: Boolean,
  labelNotMargin?: Boolean,
  rows?: number,
};

const FormInput = ({
  label,
  tooltipText = false,
  type,
  name,
  placeholder,
  register,
  errors,
  className,
  labelClassName,
  containerClass,
  refCallback,
  children,
  disabled = false,
  onChange,
  onKeyPress,
  onClick,
  onClickButton,
  required,
  value,
  checked,
  editIcon = false,
  labelNotMargin = false,
  ...otherProps
}: FormInputProps): React$Element<React$FragmentType> => {
  // handle input type
  const comp =
    type === 'textarea' ? 'textarea' : type === 'select' ? 'select' : 'input';

  const labelCheckboxRadio = (() => {
    return (
      <>
        {label}{' '}
        {editIcon && <i className="uil-pen" style={{ marginLeft: '5px' }} />}
        {tooltipText && (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={<Tooltip id="tooltip-top">{tooltipText}</Tooltip>}
          >
            <i className="uil-info-circle" style={{ marginLeft: '5px' }}></i>
          </OverlayTrigger>
        )}
      </>
    );
  })();

  return (
    <>
      {type === 'hidden' ? (
        <input
          type={type}
          name={name}
          {...(register ? register(name) : {})}
          {...otherProps}
        />
      ) : (
        <>
          {type === 'password' ? (
            <>
              <Form.Group className={containerClass}>
                {label ? (
                  <>
                    {' '}
                    {editIcon && (
                      <i className="uil-pen" style={{ marginLeft: '5px' }} />
                    )}
                    <Form.Label className={labelClassName}>
                      {label}
                      {tooltipText && (
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">{tooltipText}</Tooltip>
                          }
                        >
                          <i className="uil-info-circle"></i>
                        </OverlayTrigger>
                      )}
                    </Form.Label>{' '}
                    {children}{' '}
                  </>
                ) : null}
                <PasswordInput
                  name={name}
                  data-testid={name}
                  placeholder={placeholder}
                  refCallback={refCallback}
                  errors={errors}
                  register={register}
                  onChange={onChange}
                  className={className}
                  disabled={disabled}
                />

                {errors ? (
                  <Form.Control.Feedback
                    type="invalid"
                    role="alert"
                    className="d-block"
                  >
                    {errors.message}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </>
          ) : (
            <>
              {type === 'checkbox' || type === 'radio' ? (
                <>
                  <Form.Group className={containerClass}>
                    <Form.Check
                      onClick={onClick}
                      type={type}
                      label={labelCheckboxRadio}
                      name={name}
                      onChange={onChange}
                      data-testid={name}
                      value={value}
                      id={name}
                      checked={checked}
                      ref={(r) => {
                        if (refCallback) refCallback(r);
                      }}
                      className={className}
                      disabled={disabled}
                      isInvalid={errors ? true : false}
                      {...(register ? register(name) : {})}
                      {...otherProps}
                    />

                    {errors ? (
                      <Form.Control.Feedback type="invalid" role="alert">
                        {errors.message}
                      </Form.Control.Feedback>
                    ) : null}
                    {!errors && required && (
                      <div className="required-in-form">Obrigatório</div>
                    )}
                  </Form.Group>
                </>
              ) : (
                <Form.Group className={containerClass}>
                  {label ? (
                    <Form.Label className={labelClassName}>
                      {label}
                      {editIcon && (
                        <i className="uil-pen" style={{ marginLeft: '5px' }} />
                      )}
                      {tooltipText && (
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">{tooltipText}</Tooltip>
                          }
                        >
                          <i
                            className="uil-info-circle"
                            style={{ marginLeft: '5px' }}
                          ></i>
                        </OverlayTrigger>
                      )}
                    </Form.Label>
                  ) : (
                    <div
                      style={!labelNotMargin ? { marginTop: '2.06em' } : null}
                    ></div>
                  )}

                  <InputGroup>
                    <Form.Control
                      type={type}
                      placeholder={placeholder}
                      name={name}
                      data-testid={name}
                      disabled={disabled}
                      onChange={onChange}
                      onKeyPress={onKeyPress}
                      value={value}
                      id={name}
                      as={comp}
                      ref={(r) => {
                        if (refCallback) refCallback(r);
                      }}
                      className={className}
                      isInvalid={errors ? true : false}
                      {...(register ? register(name) : {})}
                      {...otherProps}
                      autoComplete={name}
                    >
                      {children ? children : null}
                    </Form.Control>

                    {onClickButton && (
                      <Button
                        variant="secondary"
                        id="button-addon1"
                        onClick={onClickButton}
                      >
                        <i className="mdi mdi-magnify search-icon" />
                      </Button>
                    )}

                    {errors ? (
                      <Form.Control.Feedback type="invalid" role="alert">
                        {errors.message}
                      </Form.Control.Feedback>
                    ) : null}
                    {!errors && required && (
                      <div className="required-in-form">Obrigatório</div>
                    )}
                  </InputGroup>
                </Form.Group>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default FormInput;
