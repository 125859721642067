import HttpClientPayments from '../../config/HttpClientPayments';

export class ClientPaymentsService {
  api: HttpClientPayments;

  constructor() {
    this.api = new HttpClientPayments();
  }

  postClientPayments = (payload: any) => {
    return this.api.post(`v1/client-payments`, payload);
  };

  postClientPaymentsOrders = (payload: any) => {
    return this.api.post(`v1/client-payments/orders`, payload);
  };

  cancelOrder = (payload: any) => {
    return this.api.post(`v1/client-payments/orders/actions/cancel`, payload);
  };

  getMarketplaceCodes = () => {
    return this.api.get('v1/marketplace-fees/codes');
  };

  getPaymentOrderDetails = (id: string) => {
    return this.api.get(`v1/client-payments/order/${id}`);
  };

  getMarketplacesFee = (
    id: string,
    payload: any,
    page_number: number,
    page_size: number
  ) => {
    return this.api.post(
      `v1/business-unit-fees/business_unit/${id}/marketplace_fee/search?page_number=${page_number}&page_size=${page_size}`,
      payload
    );
  };

  getMarketplaceFee = (dropsId: string) => {
    return this.api.get(`v1/business-unit-fees/${dropsId}/marketplace_fee`);
  };

  postMarketplaceFee = (dropsId: string, payload: any) => {
    return this.api.post(
      `v1/business-unit-fees/business_unit/${dropsId}/marketplace_fee`,
      payload
    );
  };

  putMarketplaceFee = (id: string, payload: any) => {
    return this.api.put(`v1/business-unit-fees/${id}/marketplace_fee`, payload);
  };

  downloadBusinessFeeCodesTemplate = () => {
    return this.api.getFile('v1/business-unit-fees/marketplace_fee/download');
  };

  uploadBusinesFeeCodesTemplate = (file: any) => {
    return this.api.postFile(
      'v1/business-unit-fees/marketplace_fee/upload',
      file
    );
  };
}
