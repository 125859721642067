export type TypeErrorTreatment = {
  message: string;
  codigo?: number;
};

const treatCodeMessages = (arrayErros: TypeErrorTreatment[], el: any) => {
  if (el.message.includes('Signatory with token')) {
    arrayErros.push({
      message: 'O signatário já existe e está associado ao mesmo modelo.',
      codigo: 400,
    });
  } else {
    arrayErros.push({ message: el.message, codigo: 400 });
  }
};

const ErrorTreatment = (error: any): TypeErrorTreatment[] => {
  const arrayErros: TypeErrorTreatment[] = [];
  if (error.response) {
    switch (error.response.status) {
      case 400:
        if (typeof error.response.data === 'string') {
          arrayErros.push({
            message: error.response.data,
            codigo: 400,
          });
        } else if (error.response.data && Array.isArray(error.response.data)) {
          error.response.data.forEach((el: any) => {
            treatCodeMessages(arrayErros, el);
          });
        } else if (typeof error.response.data === 'object') {
          if (
            error.response.data.error_messages &&
            Array.isArray(error.response.data.error_messages)
          ) {
            error.response.data.error_messages.forEach((el: any) => {
              arrayErros.push({ message: el, codigo: 400 });
            });
          } else {
            const obj = error.response.data;
            const firstKey = Object.keys(obj)[0];

            arrayErros.push({ message: obj[firstKey], codigo: 400 });
          }
        } else {
          arrayErros.push({
            message: 'Ocorreu um erro inesperado',
            codigo: 400,
          });
        }
        break;
      case 403:
        arrayErros.push({ message: 'Sem permissão', codigo: 403 });
        break;
      case 404:
        if (typeof error.response.data === 'string') {
          arrayErros.push({
            message: error.response.data,
            codigo: 404,
          });
        } else if (error.response.data && Array.isArray(error.response.data)) {
          error.response.data.forEach((el: any) => {
            arrayErros.push({ message: el.message, codigo: 404 });
          });
        } else if (typeof error.response.data === 'object') {
          const obj = error.response.data;
          const firstKey = Object.keys(obj)[0];

          arrayErros.push({ message: obj[firstKey], codigo: 404 });
        } else {
          arrayErros.push({
            message: 'Ocorreu um erro inesperado',
            codigo: 404,
          });
        }
        break;
      case 500:
        arrayErros.push({ message: 'Erro interno do servidor', codigo: 500 });
        break;
      default:
        arrayErros.push({ message: 'Ocorreu um erro inesperado' });
        break;
    }
  } else {
    arrayErros.push({ message: 'Erro interno do servidor' });
  }
  return arrayErros;
};

export { ErrorTreatment };
