// @flow
import { combineReducers } from 'redux';

import Layout from './layout/reducers';
import Cart from './cart/reducers';

export default combineReducers({
  Layout,
  Cart,
});
