import { ReactElement } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

type FormInputNumberMaskProps = {
  label?: string;
  tooltipText?: string;
  name: string;
  placeholder?: string;
  register?: any;
  errors?: any;
  refCallback?: any;
  value: any;
  onChange: (event: { target: { name: string; value: string } }) => void;
  minValue?: number;
  maxValue?: number;
  required?: boolean;
  format?: string;
  prefix?: string;
  suffix?: string;
  decimalSeparator?: string;
  disabled?: boolean;
};

function currencyFormatter(value: number) {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);

  return `${amount}`;
}

function measureFormatter(value: number) {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'unit',
    unit: 'centimeter',
  }).format(value);

  return `${amount}`;
}

const FormInputNumberMask = ({
  label,
  tooltipText,
  name,
  placeholder,
  register,
  errors,
  refCallback,
  value,
  onChange,
  minValue = 0,
  maxValue,
  required,
  format,
  prefix,
  suffix,
  decimalSeparator,
  disabled = false,
  ...otherProps
}: FormInputNumberMaskProps): ReactElement => {
  const classInvalid = `form-control ${
    errors ? 'was-validated form-control:invalid form-control is-invalid' : ''
  }`;

  return (
    <div className="form-group mb-3">
      <Form.Label>
        {label}
        {tooltipText && (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={<Tooltip id="tooltip-top">{tooltipText}</Tooltip>}
          >
            <i className="uil-info-circle" style={{ marginLeft: '5px' }} />
          </OverlayTrigger>
        )}
      </Form.Label>

      <NumberFormat
        name={name}
        id={name}
        data-testid={name}
        className={classInvalid}
        placeholder={placeholder}
        {...otherProps}
        ref={(r: any) => {
          if (refCallback) refCallback(r);
        }}
        {...(register ? register(name) : {})}
        value={value}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;

          if (maxValue) {
            return (
              formattedValue === '' ||
              (floatValue !== undefined &&
                floatValue >= minValue &&
                floatValue <= maxValue)
            );
          }
          return (
            formattedValue === '' ||
            (floatValue !== undefined && floatValue >= minValue)
          );
        }}
        isNumericString
        decimalSeparator={decimalSeparator}
        format={
          format === 'currency'
            ? currencyFormatter
            : format === 'measure'
              ? measureFormatter
              : null
        }
        prefix={prefix}
        suffix={suffix}
        disabled={disabled}
      />

      {errors ? (
        <Form.Control.Feedback type="invalid" role="alert">
          {errors.message}
        </Form.Control.Feedback>
      ) : null}
      {!errors && required && (
        <div className="required-in-form">Obrigatório</div>
      )}
    </div>
  );
};

export default FormInputNumberMask;
