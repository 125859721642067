import HttpUsersService from '../../config/HttpUsers';
import { GetFilterResponse } from './users.type';

export class UserService {
  api: HttpUsersService;

  constructor() {
    this.api = new HttpUsersService();
  }

  getFilters = (): Promise<GetFilterResponse> => {
    return this.api.get(`v1/user/filter`);
  };

  postFilter = (payload: any) => {
    return this.api.post(`v1/user/filter`, payload);
  };

  selectedFavoriteFilter = (id: string) => {
    return this.api.patch(`v1/user/filter/${id}/favorite`);
  };

  deleteFilter = (id: string) => {
    return this.api.delete(`v1/user/filter/${id}`);
  };
}
