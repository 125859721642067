/* eslint-disable react/no-array-index-key */
import { ReactElement } from 'react';
import PaginationT from 'react-js-pagination';
import { page_size } from '../../constants/pageSize';

export type PaginationProps = {
  totalRecords: number;
  currentPageNumber: number;
  updatingPage: any;
  loading: boolean;
  pageSize?: number;
};

const Pagination = ({
  totalRecords,
  currentPageNumber,
  updatingPage,
  loading,
  pageSize = page_size,
}: PaginationProps): ReactElement => {
  return (
    <PaginationT
      innerClass="pagination-default"
      activeClass="active-pagination"
      disabledClass="disabled-pagination"
      itemClass={loading ? 'disabled-pagination-link' : ''}
      prevPageText={<i className="uil uil-angle-left" />}
      firstPageText={<i className="uil uil-angle-double-left" />}
      nextPageText={<i className="uil uil-angle-right" />}
      lastPageText={<i className="uil uil-angle-double-right" />}
      activePage={currentPageNumber}
      itemsCountPerPage={pageSize}
      totalItemsCount={totalRecords}
      pageRangeDisplayed={5}
      onChange={(pageNumber: number) => {
        updatingPage(pageNumber);
      }}
    />
  );
};

export default Pagination;
