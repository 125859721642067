import HttpClientService from '../../config/HttpClient';

export class MarketplacesService {
  api: HttpClientService;

  constructor() {
    this.api = new HttpClientService();
  }

  postMarketplaces = (payload: any) => {
    return this.api.post(`v1/marketplaces`, payload);
  };

  putMarketplaces = (payload: any, id: string) => {
    return this.api.put(`v1/marketplaces/${id}`, payload);
  };

  getMarketplaces = (filter: any, page_number: number, page_size: number) => {
    return this.api.post(
      `v1/marketplaces/search?page_number=${page_number}&page_size=${page_size}`,
      filter
    );
  };

  getMarketplacesAll = (name: string) => {
    return this.api.post(
      `v1/marketplaces/search?page_number=${1}&page_size=${50}`,
      {
        name,
      }
    );
  };

  getMarketplace = (id: string) => {
    return this.api.get(`v1/marketplaces/${id}`);
  };

  getMarketplaceName = (id: string) => {
    return this.api.get(`v1/marketplaces/${id}/name`);
  };

  getWebhookMarketplace = (id: string) => {
    return this.api.get(`v1/marketplaces/${id}/webhook`);
  };

  postWebhookMarketplace = (id: string, payload: string) => {
    return this.api.post(`v1/marketplaces/${id}/webhook`, payload);
  };

  putWebhookMarketplace = (id: string, payload: string) => {
    return this.api.put(`v1/marketplaces/${id}/webhook`, payload);
  };

  putGenerateApiKey = (marketplaceId: string) => {
    return this.api.put(`v1/marketplaces/${marketplaceId}/api_key`, {});
  };
}
