import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal/';

import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
} from './index';

const Routes = (props) => {
  const { layout, user } = useSelector((state) => ({ layout: state.Layout }));

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case layoutConstants.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  let Layout = getLayout();

  return (
    <BrowserRouter>
      <Switch>
        <Route path={authProtectedFlattenRoutes.map((r) => r['path'])}>
          <Layout {...props} layout={layout} user={user}>
            <Switch>
              {authProtectedFlattenRoutes.map((route, index) => {
                return (
                  !route.children && (
                    <route.route
                      key={index}
                      path={route.path}
                      roles={route.roles}
                      exact={route.exact}
                      component={route.component}
                    />
                  )
                );
              })}
            </Switch>
          </Layout>
        </Route>

        <Route path={publicProtectedFlattenRoutes.map((r) => r['path'])}>
          <DefaultLayout {...props} layout={layout}>
            <Switch>
              {publicProtectedFlattenRoutes.map((route, index) => {
                return (
                  !route.children && (
                    <route.route
                      key={index}
                      path={route.path}
                      roles={route.roles}
                      exact={route.exact}
                      component={route.component}
                    />
                  )
                );
              })}
            </Switch>
          </DefaultLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
