import * as Yup from 'yup';

const validationSchemaFilter = Yup.object().shape({
  start_date: Yup.string().when('isEndDate', {
    is: true,
    then: Yup.string().required('Obrigatório'),
  }),
  end_date: Yup.string().when('isStartDate', {
    is: true,
    then: Yup.string().required('Obrigatório'),
  }),
});

export default validationSchemaFilter;
