import HttpClientPayments from '../../config/HttpClientPayments';

export class VaucherService {
  api: HttpClientPayments;

  constructor() {
    this.api = new HttpClientPayments();
  }

  postVoucher = (payload: any) => {
    return this.api.post(`v1/vouchers`, payload);
  };

  putVoucher = (payload: any) => {
    return this.api.put(`v1/vouchers/active`, payload);
  };

  getVouchers = (filter: any, page_number: number, page_size: number) => {
    return this.api.post(
      `v1/vouchers/search?page_number=${page_number}&page_size=${page_size}`,
      filter
    );
  };

  getVoucher = (id: string) => {
    return this.api.get(`v1/vouchers/${id}`);
  };
}
